.dropdown-left {
    div {
        position: absolute;
        right: 0;
    }
}

.form-check-input {
    background-color: var(--bg-primary);
    border-color: var(--bs-primary);
}

.form-check-input:checked {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
    position: relative;

}

.form-check-input:checked::before {
    content: '';
    display: block;
    width: 50%;
    height: 50%;
    background-color: var(--bg-primary);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form-check-label {
    color: var(--bs-primary);
}


.text-muted {
    color: var(--bs-primary) !important;
    opacity: 0.7;
}

.btn-outline-primary {
    color: var(--bs-primary);
    border-color: var(--bs-primary);
}


.btn-outline-success {
    --bs-btn-color: var(--bs-success);
}

.btn-check:checked+.btn-outline-primary,
.btn-check:active+.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary:hover,
.btn-outline-primary.dropdown-toggle.show,
.btn-primary,
.btn-primary:disabled {
    color: var(--bg-primary) !important;
    background-color: var(--bs-primary) !important;
    border-color: var(--bs-primary) !important;
}

.border-primary {
    border-color: var(--bs-primary) !important;
}

.text-primary {
    color: var(--bs-primary) !important
}

.text-background {
    color: var(--bg-primary) !important;
}

.bg-primary {
    background-color: var(--bs-primary) !important;
}

.bg-background {
    background-color: var(--bg-primary) !important;
}

.tooltip {
    position: fixed;
}

@media (max-width: 767.98px) {
    .modal-fullscreen-md-down {
        align-items: end;

        .modal-content {
            height: 80%;
        }
    }
}